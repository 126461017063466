import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 600.000000 564.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,564.000000) scale(0.100000,-0.100000)">
					<path d="M2885 4864 c-163 -16 -239 -26 -300 -40 -87 -20 -267 -74 -280 -83
-5 -4 -39 -18 -75 -31 -36 -12 -70 -26 -76 -31 -6 -5 -42 -25 -80 -44 -82 -42
-198 -114 -202 -126 -2 -5 -8 -9 -14 -9 -19 0 -154 -112 -257 -213 -258 -254
-435 -565 -525 -919 -41 -158 -41 -162 -56 -345 -15 -179 0 -415 36 -555 38
-152 49 -190 86 -288 145 -389 409 -719 769 -961 48 -32 120 -74 160 -94 39
-19 77 -39 83 -44 6 -5 40 -19 76 -31 36 -13 70 -27 75 -31 15 -10 198 -64
285 -84 243 -53 560 -55 800 -3 109 23 251 64 295 83 17 8 57 24 90 37 66 26
269 131 285 148 3 4 39 28 80 55 171 113 353 295 485 485 97 141 147 229 140
249 -3 8 -3 12 1 8 12 -11 30 23 78 144 114 292 160 590 136 884 -13 157 -13
161 -36 265 -83 375 -254 697 -508 960 -118 122 -269 250 -295 250 -5 0 -11 4
-13 9 -4 11 -166 110 -253 154 -172 88 -466 175 -645 192 -93 8 -298 14 -345
9z m-876 -1533 c29 -26 31 -32 25 -68 -7 -47 -11 -54 -44 -76 -60 -41 -140 5
-140 80 1 87 92 124 159 64z m1107 5 c36 -13 91 -59 116 -97 9 -14 21 -51 27
-81 12 -68 -2 -126 -42 -168 l-28 -29 32 -28 c17 -15 37 -40 45 -55 16 -32 20
-188 4 -188 -5 0 -10 -7 -10 -15 0 -26 -63 -87 -115 -111 -47 -22 -62 -24
-238 -24 l-187 0 2 402 3 402 25 7 c36 10 329 -2 366 -15z m365 -5 c41 -42 39
-83 -6 -128 -31 -31 -40 -35 -70 -30 -37 6 -55 19 -73 54 -32 59 14 133 83
133 27 0 44 -8 66 -29z m231 11 c5 -5 7 -186 6 -403 l-3 -394 -64 -3 c-35 -2
-68 1 -73 6 -6 6 -11 501 -8 789 0 6 10 14 23 16 29 7 109 -1 119 -11z m215 5
c6 -7 5 -786 -2 -796 -3 -6 -35 -11 -70 -11 -52 0 -65 3 -69 18 -4 14 -2 740
3 779 0 6 13 13 29 16 27 4 103 0 109 -6z m-2331 -238 c16 -7 36 -19 45 -27
15 -11 17 -11 15 5 -1 15 8 18 67 21 37 2 67 -1 67 -5 1 -4 1 -141 2 -305 l1
-296 -27 -56 c-58 -118 -187 -175 -330 -144 -22 5 -47 9 -53 10 -7 1 -13 5
-13 8 0 4 -13 13 -30 20 -31 13 -67 55 -88 104 -16 37 -7 47 65 75 l53 21 26
-39 c26 -40 75 -71 113 -71 11 0 38 7 60 16 31 12 43 25 59 62 19 44 20 80 1
51 -5 -8 -9 -11 -9 -6 0 4 -11 0 -24 -8 -39 -26 -161 -24 -216 3 -59 28 -123
111 -145 187 -26 88 -9 215 36 266 11 13 30 36 42 51 12 15 26 28 31 28 5 0
17 6 25 14 39 35 161 43 227 15z m841 -6 c36 -19 42 -20 56 -6 10 10 33 15 72
14 32 0 59 -2 62 -4 2 -2 4 -140 4 -305 l0 -301 -25 -54 c-30 -62 -52 -85
-121 -123 -43 -23 -61 -27 -130 -28 -126 0 -188 30 -247 117 -41 59 -37 70 35
101 69 30 59 33 109 -38 22 -31 79 -50 124 -42 40 8 98 62 108 101 7 29 0 47
-12 28 -23 -36 -189 -45 -252 -13 -55 28 -115 93 -130 141 -7 24 -17 48 -22
54 -13 15 -9 126 7 179 25 81 70 137 145 180 55 31 156 30 217 -1z m1950 -10
c43 -22 63 -41 91 -87 33 -53 37 -68 41 -137 4 -95 22 -87 -217 -87 -95 0
-176 -3 -179 -6 -11 -10 20 -58 56 -85 26 -20 45 -25 84 -25 47 1 54 4 91 46
35 38 45 44 66 38 41 -12 95 -46 98 -62 5 -24 -88 -119 -137 -140 -35 -15 -65
-19 -136 -17 -49 1 -101 7 -115 12 -32 14 -130 112 -130 131 0 8 -4 16 -10 18
-16 5 -27 170 -14 219 27 107 100 184 199 210 58 16 153 3 212 -28z m514 27
c14 0 11 -137 -3 -146 -7 -4 -34 -6 -61 -5 -51 3 -97 -18 -97 -43 0 -8 -5 -16
-11 -18 -14 -5 -18 -43 -18 -220 l-1 -148 -70 0 -69 0 -3 278 c-2 152 -2 282
0 287 2 11 111 11 138 1 9 -3 12 -11 8 -18 -4 -7 2 -5 14 6 20 18 121 38 150
30 8 -2 18 -4 23 -4z m-1411 -55 c3 -22 3 -148 0 -280 l-5 -240 -70 0 -70 0
-3 282 -2 282 27 3 c16 2 48 1 73 -2 44 -5 45 -6 50 -45z m-1475 -240 l0 -280
-70 0 -70 0 -3 283 -2 283 72 -3 73 -3 0 -280z"/>
<path d="M2870 3118 l0 -93 73 1 c39 1 74 3 77 4 3 1 16 4 29 6 59 11 80 65
49 125 -23 44 -37 49 -145 49 l-83 0 0 -92z"/>
<path d="M2877 2883 c-4 -3 -7 -51 -7 -105 l0 -98 78 0 c83 0 134 11 155 32
59 58 40 138 -39 169 -29 10 -177 13 -187 2z"/>
<path d="M1473 2983 c-28 -6 -72 -50 -89 -90 -48 -112 35 -243 143 -228 41 5
64 20 91 60 55 79 30 205 -48 245 -38 20 -53 22 -97 13z"/>
<path d="M2310 2983 c-68 -26 -104 -89 -98 -170 10 -124 119 -188 214 -126 32
20 64 91 64 139 0 50 -31 118 -63 139 -26 17 -92 27 -117 18z"/>
<path d="M4182 2968 c-18 -11 -32 -24 -32 -29 0 -5 0 -12 0 -16 0 -11 198 -14
204 -4 3 5 -5 20 -17 35 -31 36 -109 43 -155 14z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
